import AgentSettingsService from '@/services/account/agentSettingsService';

const state = () => ({
    agentSettings: JSON.parse(localStorage.getItem('agentSettings')) || {
        uuid: null,
        name: null,
        conversation_tone: null,
        account_id: null,
        enabled: false,
        skills: [
            {
                account_id: '',
                skill_id: '',
                enabled: false,
                internal_name: '',
                config_values: {},
                date_created: 0,
                date_updated: 0,
            },
        ],
        date_created: 0,
    },
});

const getters = {
    agentSettings: (state) => state.agentSettings,
    getSkillByInternalName: (state) => (internal_name) => {
        if (state.agentSettings && state.agentSettings.skills) {
            return state.agentSettings.skills.find((skill) => skill.internal_name === internal_name) || null;
        }
        return null;
    },
    isAgentSettingsEnabled: (state) => state.agentSettings.enabled,
    getSkills: (state) => state.agentSettings.skills || [],
    hasShopifySkill: (state) => {
        return state.agentSettings.skills.some((skill) => skill.internal_name === 'Shopify');
    },
};

const actions = {
    clearAgentSettingsState({ commit }) {
        localStorage.removeItem('agentSettings');
        commit('CLEAR_AGENT_SETTINGS');
    },

    async updateAgentSetting({ commit }) {
        const newAgentSettings = await AgentSettingsService.getAgentSettings();
        commit('SET_AGENT_SETTINGS', newAgentSettings.data);
        localStorage.setItem('agentSettings', JSON.stringify(newAgentSettings.data));
    },

    async switchAgentSettings({ commit, state }, newEnabledState) {
        if (newEnabledState === state.agentSettings.enabled) {
            return; // No change needed
        }

        const previousState = state.agentSettings.enabled;
        commit('SWITCH_AGENT_SETTINGS', newEnabledState);

        try {
            await (newEnabledState
                ? AgentSettingsService.enableAgentSettings()
                : AgentSettingsService.disableAgentSettings());
        } catch (error) {
            commit('SWITCH_AGENT_SETTINGS', previousState); // Rollback to previous state
            throw error; // Re-throw to allow caller to handle if needed
        }
    },

    async subscribeToAgentSettingsUpdates({ state, dispatch }) {
        const accountId = state.agentSettings.account_id;
        const topic = `/account/${accountId}/agent/${accountId}`;
        try {
            await dispatch(
                'websocket/subscribe',
                {
                    topic,
                    callback: (data) => {
                        dispatch('handleAgentSettingsUpdate', data);
                    },
                },
                { root: true },
            );
        } catch (error) {
            console.error(`Failed to subscribe to ${topic}:`, error);
        }
    },

    unsubscribeFromAgentSettingsUpdates({ state, dispatch }) {
        const accountId = state.agentSettings.account_id;
        const topic = `/account/${accountId}/agent/${accountId}`;
        dispatch('websocket/unsubscribe', { topic }, { root: true });
    },

    handleAgentSettingsUpdate({ state, commit }, event) {
        if (!event || !event.data) return;

        try {
            switch (event.name) {
                case 'AgentSettingsDisabled':
                    console.log('AgentSettingsDisabled');
                    if (state.agentSettings.enabled === false) {
                        return; // No change needed
                    }
                    commit('SWITCH_AGENT_SETTINGS', false);
                    break;
                case 'AgentSettingsEnabled':
                    console.log('AgentSettingsEnabled');
                    if (state.agentSettings.enabled === true) {
                        return; // No change needed
                    }
                    commit('SWITCH_AGENT_SETTINGS', true);
                    break;
                case 'AgentSettingsEnablingError':
                    console.log('AgentSettingsEnablingError');
                    if (state.agentSettings.enabled === false) {
                        return; // No change needed
                    }
                    commit('SWITCH_AGENT_SETTINGS', false);
                    break;

                case 'SkillAdded':
                    console.log('SkillAdded');
                    if (event.data) {
                        const updatedSkills = [...state.agentSettings.skills];
                        const skillIndex = updatedSkills.findIndex((skill) => skill.skill_id === event.data.skill_id);

                        if (skillIndex !== -1) {
                            updatedSkills[skillIndex] = event.data;
                        } else {
                            updatedSkills.push(event.data);
                        }

                        commit('UPDATE_SKILLS', updatedSkills);
                    }
                    break;
                default:
                    console.warn(`Unhandled agent settings event: ${event.name}`);
            }
        } catch (error) {
            console.error('Error parsing agent settings update data:', error);
        }
    },
};

const mutations = {
    SET_AGENT_SETTINGS(state, agentSettings) {
        state.agentSettings = { ...agentSettings };
    },
    SWITCH_AGENT_SETTINGS(state, isEnabled) {
        state.agentSettings.enabled = isEnabled;
    },
    CLEAR_AGENT_SETTINGS(state) {
        state.agentSettings = {
            uuid: null,
            name: null,
            conversation_tone: null,
            account_id: null,
            enabled: false,
            skills: [
                {
                    skill_id: null,
                    enabled: null,
                    internal_name: null,
                    internal_prompt: null,
                    date_created: 0,
                    date_updated: 0,
                },
            ],
            date_created: 0,
        };
    },
    UPDATE_SKILLS(state, skills) {
        state.agentSettings.skills = skills;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
