<template>
    <div
        id="dashboard-layout"
        class="wrapper"
        :class="[{ 'nav-open': $sidebar.showSidebar }, { rtl: $route.meta.rtlActive }]"
    >
        <AdminNavBar v-if="isWalkonLivosAccount" />

        <side-bar :active-color="sidebarBackground" :data-background-color="sidebarBackgroundColor">
            <div slot="userMenu">
                <!--         <user-menu></user-menu>-->
                <mobile-menu></mobile-menu>
            </div>
            <template slot="links">
                <sidebar-item :link="{ name: 'Dashboard', icon: 'dashboard', path: '/dashboard' }"></sidebar-item>
                <sidebar-item
                    :link="{
                        name: 'Inbox',
                        icon: 'question_answer',
                        path: '/conversations',
                    }"
                ></sidebar-item>
                <sidebar-item
                    :link="{
                        name: 'Training',
                        icon: 'neurology',
                        path: '/training',
                    }"
                ></sidebar-item>
                <sidebar-item
                    :link="{
                        name: 'Skills',
                        icon: 'rocket_launch',
                        path: '/skills',
                    }"
                ></sidebar-item>
                <sidebar-item :link="{ name: 'Playground', icon: 'terminal', path: '/playground' }"></sidebar-item>
                <sidebar-item
                    v-if="isCatalogSkillActive"
                    :link="{
                        name: 'Catalog',
                        icon: 'storefront\n',
                        path: '/catalog',
                    }"
                ></sidebar-item>
                <!-- <sidebar-item
                    v-if="isSalesSkillActive"
                    :link="{
                        name: 'Sales',
                        icon: 'payments',
                        path: '/sales',
                    }"
                ></sidebar-item> -->
                <!--        <sidebar-item :link="{-->
                <!--          name: 'Communication Channels', icon: 'electrical_services', path: '/communication-channels'-->
                <!--        }"></sidebar-item>-->
            </template>

            <template slot="fixedBottom">
                <sidebar-paid-plan-panel v-if="$store.getters['membership/ifSubscribed']" />
                <sidebar-free-plan-panel v-else />
                <div class="sidebar-separator"></div>
                <div>
                    <SidebarNotificationsBellButton
                        @notificationPanelOpen="toggleNotificationsPanel"
                        :link="{ name: 'Notifications', icon: 'notifications' }"
                        :unreadCount="unreadNotificationsCount"
                        ref="notificationsPanel"
                    ></SidebarNotificationsBellButton>
                </div>

                <sidebar-profile-button />
            </template>
        </side-bar>
        <sidebar-notifications-panel
            v-if="showNotificationPanel"
            v-click-outside="handleClickOutside"
            @notificationsRead="getUnreadNotificationsCount"
            @notificationsReadAll="setAllNotificationsAsRead"
            @closePanel="showNotificationPanel = false"
        ></sidebar-notifications-panel>

        <div class="main-panel" :class="{ 'main-panel--padding': isWalkonLivosAccount }">
            <top-navbar v-if="shouldShowNavbar"></top-navbar>

            <div class="md-layout" :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
                <zoom-center-transition :duration="200" mode="out-in">
                    <!-- your content here -->
                    <router-view></router-view>
                </zoom-center-transition>
            </div>

            <content-footer v-show="$route.path !== '/conversations'" v-if="!$route.meta.hideFooter"></content-footer>
        </div>

        <TheModalWindow></TheModalWindow>

        <!-- Add the Fab component -->
        <SupportBubbleFab v-if="!isInChatPage" />
    </div>
</template>
<script>
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
    if (hasElement(className)) {
        new PerfectScrollbar(`.${className}`);
        document.getElementsByClassName(className)[0].scrollTop = 0;
    } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
            initScrollbar(className);
        }, 100);
    }
}

function reinitScrollbar() {
    let docClasses = document.body.classList;
    let isWindows = navigator.platform.startsWith('Win');
    if (isWindows) {
        // if we are on windows OS we activate the perfectScrollbar function
        initScrollbar('sidebar');
        initScrollbar('sidebar-wrapper');
        initScrollbar('main-panel');

        docClasses.add('perfect-scrollbar-on');
    } else {
        docClasses.add('perfect-scrollbar-off');
    }
}

import clickOutside from '@/utils/on-click-outside.js';
import notificationsService from '@/services/notifications/notificationsService.js';
import TopNavbar from './TopNavbar.vue';
import AdminNavBar from './AdminNavBar.vue';
import ContentFooter from './ContentFooter.vue';
import MobileMenu from './Extra/MobileMenu.vue';
// import FixedPlugin from "../../FixedPlugin.vue";
import UserMenu from './Extra/UserMenu.vue';
import { ZoomCenterTransition } from 'vue2-transitions';
import SidebarProfileButton from '@/components/SidebarPlugin/sidebarProfileButton.vue';
import SidebarFreePlanPanel from '@/components/SidebarPlugin/SidebarFreePlanPanel.vue';
import SidebarPaidPlanPanel from '@/components/SidebarPlugin/sidebarPaidPlanPanel.vue';
import SidebarNotificationsBellButton from '../../../components/SidebarPlugin/SidebarNotificationsBellButton.vue';
import SidebarNotificationsPanel from '../../../components/SidebarPlugin/Notifications/SidebarNotificationsPanel.vue';
import { mapActions, mapGetters } from 'vuex';

// Import the Fab component
import SupportBubbleFab from '@/components/SupportBubble/SupportBubbleFab.vue';

export default {
    name: 'DashboardLayout',
    components: {
        SidebarPaidPlanPanel,
        SidebarFreePlanPanel,
        SidebarProfileButton,
        TopNavbar,
        ContentFooter,
        // FixedPlugin,
        MobileMenu,
        UserMenu,
        ZoomCenterTransition,
        AdminNavBar,
        SidebarNotificationsBellButton,
        SidebarNotificationsPanel,
        SupportBubbleFab,
    },
    directives: {
        clickOutside,
    },
    computed: {
        ...mapGetters('agentSettings', ['agentSettings']),

        isSalesSkillActive() {
            if (!this.agentSettings.skills) return false; // No skills

            const skills = [...this.agentSettings.skills];
            return skills.some((skill) => skill.skill_id === '3e6a68e0-90d4-431e-a520-51d17a185580');
        },

        isCatalogSkillActive() {
            if (!this.agentSettings.skills) return false; // No skills

            const skills = [...this.agentSettings.skills];
            return skills.some((skill) => skill.skill_id === '796fca14-e437-404c-94bd-7bb97cca320c');
        },

        isWalkonLivosAccount() {
            if (!this.$store.state.user.user || !this.$store.state.user.user.email) {
                return false;
            }

            const email = this.$store.state.user.user.email;
            const domain = email.substring(email.lastIndexOf('@') + 1);
            return domain === 'walkonlivos.com' || domain === 'lixsa.ai';
        },

        isInChatPage() {
            return this.$route.path === '/conversations';
        },

        shouldShowNavbar() {
            return this.isMobile || !this.isInChatPage;
        },
    },
    data() {
        return {
            sidebarBackgroundColor: 'white',
            sidebarBackground: 'green',
            sidebarBackgroundImage: './img/sidebar-2.jpg',
            sidebarMini: true,
            sidebarImg: true,
            unreadNotificationsCount: 0,
            showNotificationPanel: false,
            recentOpenNotificationsPanel: false,
            isMobile: false,
        };
    },
    methods: {
        ...mapActions('websocket', ['subscribe', 'unsubscribe']),
        subscribeToNotifications() {
            const topic = `/account/${this.$store.state.user.user.account_id}/notifications`;
            this.subscribe({
                topic,
                callback: this.handleNewNotification,
            });
        },
        handleNewNotification(notification) {
            this.getUnreadNotificationsCount();

            if (this.showNotificationPanel) {
                this.refreshNotifications();
            }
        },
        refreshNotifications() {
            this.getUnreadNotificationsCount();

            this.$refs.notificationsPanel.getPagedNotificationsByType(
                this.$refs.notificationsPanel.selectedTab.toUpperCase(),
            );
        },
        toggleSidebar() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.displaySidebar(false);
            }
        },
        minimizeSidebar() {
            if (this.$sidebar) {
                this.$sidebar.toggleMinimize();
            }
        },
        toggleNotificationsPanel() {
            this.showNotificationPanel = !this.showNotificationPanel;

            if (this.showNotificationPanel) {
                this.recentOpenNotificationsPanel = true;
            }
        },
        handleClickOutside(event) {
            if (!this.recentOpenNotificationsPanel && this.showNotificationPanel) {
                if (!event?.target?.closest('#sidebar-notifications-panel')) {
                    this.showNotificationPanel = false;
                }
            }

            this.recentOpenNotificationsPanel = false;
        },

        async getUnreadNotificationsCount() {
            const { notifications_config } = this.$store.getters['user/user'];

            const mainTypes = Object.keys(notifications_config.activeNotifications);
            const ignoredTypes = [];

            for (const mainType of mainTypes) {
                const subTypes = Object.keys(notifications_config.activeNotifications[mainType]);
                for (const subType of subTypes) {
                    if (!notifications_config.activeNotifications[mainType][subType]) {
                        ignoredTypes.push(subType);
                    }
                }
            }

            const unreadCount = await notificationsService.getUnreadNotificationsCount(ignoredTypes);

            this.unreadNotificationsCount = unreadCount.data;
        },
        setAllNotificationsAsRead() {
            this.unreadNotificationsCount = 0;
        },
        checkScreenSize() {
            this.isMobile = window.innerWidth <= 768;
        },
    },
    updated() {
        reinitScrollbar();
    },
    async mounted() {
        this.subscribeToNotifications();
        reinitScrollbar();
        this.getUnreadNotificationsCount();
        document.addEventListener('click', this.handleClickOutside);

        // Check initial screen size
        this.checkScreenSize();
        // Add resize listener
        window.addEventListener('resize', this.checkScreenSize);
    },
    beforeDestroy() {
        const topic = `/account/${this.$store.state.user.user.account_id}/notifications`;
        this.unsubscribe({
            topic,
            callback: this.handleNewNotification,
        });

        document.removeEventListener('click', this.handleClickOutside);
        window.removeEventListener('resize', this.checkScreenSize);
    },
    watch: {
        sidebarMini() {
            this.minimizeSidebar();
        },
    },
};
</script>
<style lang="scss" scoped>
$scaleSize: 0.95;

@keyframes zoomIn95 {
    from {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }

    to {
        opacity: 1;
    }
}

.main-panel--padding {
    padding-top: 40px;
}

//.main-panel--not-chat-padding {
//  padding-top: 40px;
//}

.main-panel .zoomIn {
    animation-name: zoomIn95;
}

@keyframes zoomOut95 {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        transform: scale3d($scaleSize, $scaleSize, $scaleSize);
    }
}

.main-panel .zoomOut {
    animation-name: zoomOut95;
}
</style>
