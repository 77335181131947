import axios from 'axios';
import store from '@/store';
import { v4 as uuidv4 } from 'uuid';

const baseURL = process.env.VUE_APP_API_URL;

const getAuthHeader = (token) => {
    const devMode = store.state.user.devMode;
    if (devMode) {
        return { 'X-API-KEY': `${token}` };
    } else {
        return { Authorization: `Bearer ${token}` };
    }
};

const apiRequest = async (method, url, data = null, params = {}) => {
    try {
        return store.dispatch('user/apiCall', async (token) => {
            const config = {
                method: method,
                url: `${baseURL}/${url}`,
                headers: getAuthHeader(token),
                params: { account: store.state.user.user.account_id, ...params },
                data: data,
            };
            return await axios(config);
        });
    } catch (error) {
        throw error;
    }
};

const userService = {
    fetchUsers(page = 0, size = 20) {
        return apiRequest('get', `users/account/${store.state.user.user.account_id}`, null, { page, size });
    },

    fetchUser(userId) {
        return apiRequest('get', `users/${userId}`);
    },

    async inviteUser(userData) {
        const requestData = {
            user_id: uuidv4(),
            username: userData.username,
            account_id: store.state.user.user.account_id,
            permission: userData.permission,
            title: userData.title,
            notifications_config: {
                push_notification_ids: [],
                active_notifications: {
                    conversation: {
                        conversation_sentiment_changed: true,
                        conversation_window_created: true,
                        conversation_window_updated: true,
                    },
                },
            },
            email: userData.email,
        };

        const response = await apiRequest('post', `users/invite/${store.state.user.user.account_id}`, requestData);
        return {
            uuid: requestData.user_id,
            response,
        };
    },

    editUser(userId, updateData) {
        return apiRequest('post', `users/${userId}`, updateData);
    },

    addPushNotificationToken(userId, token) {
        const requestData = {
            token,
        };
        return apiRequest('post', `users/${userId}/add-notification-id`, requestData);
    },

    deleteUser(userId) {
        return apiRequest('delete', `users/${userId}`);
    },

    acceptInvitation(userId, externalId) {
        const requestData = {
            external_id: externalId,
        };
        return apiRequest('post', `users/${userId}/accept-invitation`, requestData);
    },
};

export default userService;
