import Vue from 'vue';
import Vuex from 'vuex';
import user from '@/store/modules/user';
import useModalStore from '@/store/modules/useModalStore';
import membership from '@/store/modules/membership';
import websocket from '@/store/modules/websocket';
import createLogger from '@/store/plugins/logger';
import agentSettings from '@/store/modules/agentSettings';
import activeFilters from './modules/activeFilters';
import abandonedCheckout from './modules/abandonedCheckout';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

// Custom action filter to exclude 'user/apiCall'
const customActionFilter = (action) => {
    return action.type !== 'user/apiCall';
};

export default new Vuex.Store({
    modules: {
        user,
        modalStore: useModalStore,
        membership,
        websocket,
        agentSettings,
        activeFilters,
        abandonedCheckout,
    },
    strict: debug,
    plugins: debug
        ? [
              createLogger({
                  actionFilter: customActionFilter,
              }),
          ]
        : [],
});
